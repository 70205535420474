import React from 'react';
import classes from './MainContainer.module.scss';


const Loader = (props)=>{
return (
<div className={classes.spinner} data-testid="loader" style={{height: props?.height}}>
    <div className={classes.spinner__circle}>
      <div className={classes.spinner__circle_dot}></div>

      <div className={classes.spinner__circle_inner}></div>
    </div>
  </div>)
};

export default Loader;