import './App.scss';
import MainContainer from './components/mainContainer/MainContainer';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';

const App = ({ msalInstance }) => {

  return (
    <div data-testid="App">
      <MsalProvider instance={msalInstance}>
        <MainContainer />
      </MsalProvider>
    </div>
  );
};
export default App;
