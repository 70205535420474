import React, {useEffect} from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { NO_ACTIVE_OPERATION } from "../common/constants";
import { removeSurveyDataFromLocal } from '../../Utils/Utils';


const LoginCallback = ()=>{
    const {inProgress} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const naviagte = useNavigate();

    useEffect(()=>{
        removeSurveyDataFromLocal();
        if(inProgress === NO_ACTIVE_OPERATION) {
        if(isAuthenticated) {
            naviagte('/');
        } else {
            naviagte('/');
        }
    } else {
        // do nothing
    }
    }, [isAuthenticated])
    
    
    return <div></div>; // Return nothing - redirect to required page
};

export default LoginCallback;