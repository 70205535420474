import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { onRemoveLogs } from '../../Utils/Utils';
import Header from '../common/Header';
import routes from '../common/routes';
import Loader from './loader';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const MainContainer = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (!isAuthenticated && inProgress === 'none') {
      // If not authenticated and not loading, initiate login process
      instance.loginRedirect().catch((e) => {
        console.error('Login failed:', e);
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  onRemoveLogs();
  return isAuthenticated === true ? (
    <>
      <Header />
      <Routes>
        {routes.map((item, i) => (
          <Route path={item.path} element={item.element} key={i} />
        ))}
      </Routes>
    </>
  ) : (
    <Loader />
  );
};

export default MainContainer;
