import { axiosAzure } from '../interceptors/axios-azure';

const { REACT_APP_API_BASE_URL_MEG } = process.env;


export const httpGetVerifyClrEnrlmnt = (acquireToken) => {
  let url = `${REACT_APP_API_BASE_URL_MEG}/verify`;
  return axiosAzure({ method: 'get', url, acquireToken });
};

export const httpGetRedirectClrEnrlmnt = (acquireToken) => {
  const url = `${REACT_APP_API_BASE_URL_MEG}/redirect`;
  return axiosAzure({ method: 'get', url, acquireToken });
};
