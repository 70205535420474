import React from 'react';
import Identity from '../identity';
import LoginCallback from '../welcome/loginRedirection';

const routes = [
  {
    path: '/dashboard',
    element: <LoginCallback />,
    exact: true,
  },
  {
    path: '/',
    element: <Identity />,
    exact: true,
  },
  // {
  //   path: '/unauthorized',
  //   element: <ErrorPage type="unauthorized" />,
  // },
  // {
  //   path: '/server-error',
  //   element: <ErrorPage type="serverError" />,
  // },
];

export default routes;
