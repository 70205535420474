import React, { useState, useEffect, useMemo } from 'react';
import './styles.scss';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  httpGetRedirectClrEnrlmnt,
  httpGetVerifyClrEnrlmnt,
} from '../../api/REST_services_meg/verifyClear';
import verifyImage from '../../assets/image.svg';
import Loader from '../mainContainer/loader';
import { useToken } from '../../hooks/useToken';
import {
  getEnforcedUserKey,
  getFromSession,
  storeToSession,
} from '../../Utils/Utils';

const learnMorePwdLessLink =
  'https://compass.t-mobile.com/compass?id=kb_article&sysparm_article=KB00107657';
const learnMoreIdpLink =
  'https://compass.t-mobile.com/compass?id=kb_article&sysparm_article=KB00108367';
const privacyPolicyLink =
  'https://www.t-mobile.com/content/digx/tmobile/us/en/privacy-center/privacy-notices/employee-contractor-privacy-notice.html';
const surveyLink = 'https://forms.office.com/r/sMcC90z85m?origin=lprLink';

const initialUserState = {
  enrollmentStatus: false,
  enforcedEnrollment: false,
  errorMessage: '',
};

const getBackgroundImage = (enrollmentStatus, enforcedEnrollment) => {
  if (enrollmentStatus) {
    return 'verify-complete-bg-img';
  }
  if (enforcedEnrollment) {
    return 'enforced-enrollment-bg-img';
  }
  return 'onload-bg-img';
};

const Identity = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { acquireToken } = useToken();
  const sessionKey = getEnforcedUserKey(instance?.getActiveAccount()?.username);

  const initUser = useMemo(() => {
    return {
      ...initialUserState,
      enforcedEnrollment: getFromSession(sessionKey, false) === 'true',
    };
  }, []);

  // Define user state and loading state
  const [user, setUser] = useState(initUser);
  const [loading, setLoading] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState(
    getBackgroundImage(user.enrollmentStatus, user.enforcedEnrollment)
  );

  // Function to reset user state to initial values
  const resetUserState = () => {
    setUser(initUser);
  };

  useEffect(() => {
    apiCall();
  }, [acquireToken]);

  // Error handler with a single message for all 4xx and 5xx errors, including 401 Unauthorized
  const handleError = (error) => {
    if (error?.response?.status === 401) {
      instance.loginRedirect();
    } else {
      const errorMessage = 'Something went wrong. Please try again later.';
      setUser((prevUser) => ({
        ...prevUser,
        errorMessage,
      }));
    }
  };

  // API call to fetch enrollment status
  const apiCall = async () => {
    if (isAuthenticated) {
      try {
        setLoading(true);
        resetUserState();

        const response = await httpGetVerifyClrEnrlmnt(acquireToken);

        const enrollmentStatus = response?.data?.enrollmentStatus || false;
        const enforcedEnrollment = response?.data?.enforcedEnrollment || false;
        const userMessage = response?.data?.userMessage;

        setUser({
          ...user,
          enrollmentStatus,
          enforcedEnrollment,
          errorMessage: userMessage,
        });

        storeToSession(sessionKey, enforcedEnrollment);

        // Update background image based on enforcedEnrollment or enrollmentStatus
        setBackgroundImage(
          getBackgroundImage(enrollmentStatus, enforcedEnrollment)
        );
      } catch (error) {
        console.error('httpGetVerifyClrEnrlmnt submission error:', error);
        handleError(error); // Handle 401 error and others
      } finally {
        setLoading(false);
      }
    }
  };

  const onVerifyClearHandler = async () => {
    try {
      setLoading(true);
      setUser({
        ...user,
        errorMessage: undefined,
      });

      const response = await httpGetRedirectClrEnrlmnt(acquireToken);

      const clearRedirectUrl = response?.data?.endpoint;
      window.location.href = clearRedirectUrl;
    } catch (error) {
      console.error('httpGetRedirectClrEnrlmnt submission error:', error);
      handleError(error); // Handle 401 error and others
    } finally {
      setLoading(false);
    }
  };

  // Conditionally render loader or main content based on the loading state
  return (
    <div
      data-testid="identity-container"
      className={`container identity-main ${backgroundImage}`}
    >
      {loading ? (
        <div className="loader-overlay">
          <Loader />
        </div>
      ) : (
        <>
          {user.enforcedEnrollment && !user.enrollmentStatus && (
            <div className="action-required-message">
              <p>
                ACTION REQUIRED! <br /> In order to restore network access, you
                must complete Identity Verification. Click Verify with CLEAR to
                begin the process.
              </p>
            </div>
          )}
          {/* Render the button based on enrollment status */}
          {user.enrollmentStatus ? (
            <div className="white-box">
              <button
                className="verify-button"
                id="verify_button"
                type="submit"
                disabled
                style={{ opacity: 0.4, pointerEvents: 'none' }}
              >
                <img src={verifyImage} alt="Verify with CLEAR" />
                Verify with CLEAR
              </button>
            </div>
          ) : (
            <div className="white-box">
              <button
                className="verify-button"
                id="verify_button_d"
                type="button"
                onClick={onVerifyClearHandler}
              >
                <img src={verifyImage} alt="Verify with CLEAR" />
                Verify with CLEAR
              </button>
            </div>
          )}

          <div className="white-box">
            {user.enrollmentStatus && (
              <p
                style={{ fontWeight: 'bold', fontSize: '12px', color: 'green' }}
              >
                {`${instance?.getActiveAccount()?.username} successfully verified in CLEAR`}
              </p>
            )}
            {user.errorMessage && (
              <div className="error-message-container">
                <p className="error-message" style={{ color: 'red' }}>
                  {user.errorMessage}
                </p>
              </div>
            )}

            {/* Outcome box based on enrollment status */}
            {user.enrollmentStatus === false ? (
              <div
                className={`${
                  user.errorMessage ? 'outcome-box-exit' : 'outcome-box-greyed'
                }`}
                id="outcome_box"
              >
                Not yet verified
              </div>
            ) : (
              <div className="outcome-box-success" id="outcome_box">
                Verified
              </div>
            )}

            <div className="link-container">
              {/* Links */}
              {!user.enforcedEnrollment && (
                <a
                  href={learnMorePwdLessLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="learn-more-pwd-less"
                >
                  Learn More About Passwordless
                </a>
              )}
              <a
                href={learnMoreIdpLink}
                target="_blank"
                rel="noopener noreferrer"
                className="learn-more-pwd-less"
              >
                Learn More About Identity Verification
              </a>

              <a
                href={privacyPolicyLink}
                target="_blank"
                rel="noopener noreferrer"
                className="learn-more-pwd-less"
              >
                Privacy Policy
              </a>
              {!user.enforcedEnrollment && (
                <a
                  href={surveyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="survey"
                >
                  Identity Verification Enrollment Survey
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Identity;
