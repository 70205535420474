import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import mainContainerClasses from '../mainContainer/MainContainer.module.scss';
import TMOLogo from '../../assets/Icons_Illustrations/Universal_Logos_Icons/tmo_logo_v2.svg';
import burgerMenu from '../../assets/Icons_Illustrations/burgerMenu.svg';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const Header = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const pathname = useLocation().pathname;
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleBurgerMenuClick = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
    setShowDropdownMenu((prevState) => !prevState); // Toggle state
  };

  // Toggle the showMenu state based on the authentication status
  useEffect(() => {
    if (isAuthenticated) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.id === 'idSignOut') {
        signOut();
        return;
      }

      // Prevent closing the menu when clicking on the burger image or dropdown
      if (event.target.id === 'idDropdownMenu' || event.target.id === 'idBurgerImg') {
        return; // Do nothing if clicking within the menu or on the burger image
      }

      // Hide the dropdown menu if clicked outside of it
      setShowDropdownMenu(false);
      console.log("Setting showDropdownMenu to false because clicked outside.");
    };

    // Add click event listener to the document
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // Function to handle user sign out
  const signOut = async () => {
    sessionStorage.clear();
    await instance.logoutRedirect();
    setShowMenu(false);
    setShowDropdownMenu(false);
  };

  // Handle logo click navigation
  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  return (
    <header className={mainContainerClasses.headerStyle}>
      <div className={mainContainerClasses.headerLayout}>
        {/* Header logo and title */}
        <span className={mainContainerClasses.headerText} onClick={() => pathname !== '/' && handleLogoClick()}>
          <img src={TMOLogo} alt="logo" />
          <span className={mainContainerClasses.headerTitle}>Passwordless and Identity Verification Enrollment</span>
        </span>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%', position: 'relative' }}>
          {/* Display the user's email if authenticated */}
          {isAuthenticated && (
            <span id="idEmailInDropdown" className={mainContainerClasses.headerEmail}>
              {instance?.getActiveAccount()?.name}
            </span>
          )}

          {/* Show burger menu if authenticated and showMenu is true */}
          {showMenu && (
            <img
              id="idBurgerImg"
              src={burgerMenu}
              alt="menu"
              tabIndex="0"
              onClick={handleBurgerMenuClick}
              className={mainContainerClasses.burgerMenuImg}
              style={{ cursor: 'pointer' }} // Add cursor pointer for visual feedback
            />
          )}
        </span>

        {/* Dropdown menu for burger menu */}
        {showDropdownMenu && (
          <div
            id="idDropdownMenu"
            className={mainContainerClasses.burgerSubMenu}
            data-testid="dropdown"
            style={{ display: 'block', position: 'absolute', top: '60px', right: '10px', backgroundColor: '#fff', border: '2px solid #ccc', zIndex: 9999 }} // Forced styles for debugging
          >
            {/* Email section inside dropdown */}
            <div>
              {instance?.getActiveAccount()?.username || 'No Email Found'}
            </div>

            <span className={mainContainerClasses.headerSubmenuSeparator}>
              <hr />
            </span>

            <span
              id="idSignOut"
              role="link"
              className={mainContainerClasses.signOut}
              style={{ cursor: 'pointer' }} // Add cursor pointer for visual feedback
              onClick={signOut}
            >
              Sign Out
            </span>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
