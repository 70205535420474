import axios from 'axios';

export const axiosAzure = async ({ acquireToken, method = 'get', url, data, otherHeaders = null }) => {
  if (!acquireToken) {
    throw new Error('Invalid or missing parameters: acquireToken');
  }
  const { idToken } = await acquireToken()

  // Validate the URL to prevent SSRF attacks
  const isValidUrl = validateUrl(url);
  if (!isValidUrl) {
    throw new Error('Invalid URL: SSRF protection - URL not allowed');
  }

  const headers = {
    ...otherHeaders,
    Authorization: `Bearer ${idToken}`,
    'X-Auth-Originator': idToken,
  };

  return axios({
    method,
    url,
    data,
    headers,
  })
};

// Helper function to validate URLs
const validateUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    // Ensure the URL is using HTTP or HTTPS protocols
    if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
      console.error('Invalid protocol:', parsedUrl.protocol);
      return false;
    }

    // Disallow URLs pointing to internal IP ranges
    const hostname = parsedUrl.hostname;
    if (isInternalIp(hostname)) {
      console.error('Internal IP not allowed:', hostname);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Invalid URL format:', error);
    return false;
  }
};

// Helper function to check if the IP is internal
const isInternalIp = (hostname) => {
  // Regex for IPv4 and IPv6 private addresses and localhost
  const privateIpRegex = /^(localhost|127\.0\.0\.1|::1|10\.\d{1,3}\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2\d|3[01])\.\d{1,3}\.\d{1,3}|192\.168\.\d{1,3}\.\d{1,3}|fc00::|fe80::)/;

  return privateIpRegex.test(hostname);
};
