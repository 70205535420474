import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { azureAuthConfig } from './config/azureConfig';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// https://mswjs.io/docs/getting-started/integrate/browser
// To execute mock worker during development runtime...
/*
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}
*/

const pca = new PublicClientApplication(azureAuthConfig);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    console.log('login success');
    pca.setActiveAccount(event.payload.account);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log('login failure');
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App msalInstance={pca} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
