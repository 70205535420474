export const azureAuthConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENTID_DEV, // Update Application (client) ID
        authority: process.env.REACT_APP_TENANT_ID, // Endpoint > OAuth 2.0 authorization endpoint (v2)
        redirectUri: '/dashboard',  // After login the azure will redirect the user back to this location,
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};
