import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export function useToken() {
  const { instance, accounts } = useMsal();

  const acquireToken = useCallback(async () => {
    if (!accounts || accounts.length === 0) {
      console.error('No accounts available for acquiring token');
      return {};
    }

    try {
      const accessTokenRequest = {
        scopes: ['openid', 'profile'],
        account: accounts[0],
      };

      // Try to acquire the token silently
      const { accessToken, idToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );

      return { accessToken, idToken };
    } catch (error) {
      // Check if the error is due to needing interaction from the user
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest); // Redirect for user interaction
      } else {
        console.error('Unexpected error acquiring token:', error);
      }
      return {};
    }
  }, [instance, accounts]);

  return {
    acquireToken,
  };
}
